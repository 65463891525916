import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-payment',
    templateUrl: './payment.component.html',
    styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {

    url;
    amountCharge;
    currencyCode;
    currencySymbol;
    activityId;
    urlExtension = '/api/payments/checkout';
    languageCode;

    constructor(
        private activatedRoute: ActivatedRoute,
        private http: HttpClient
    ) {}

    ngOnInit(): void {
        localStorage.setItem('paymentComplete', 'false');
        localStorage.setItem('error', 'false');
        this.amountCharge = this.activatedRoute.snapshot.queryParams['amountCharge'];
        this.currencyCode = this.activatedRoute.snapshot.queryParams['currencyCode'];
        this.currencySymbol = this.activatedRoute.snapshot.queryParams['currencySymbol'];

        if (this.currencyCode === undefined) {
            // fallback to currency
            this.currencyCode = 'EUR';
            this.currencySymbol = this.getCurrencySymbol();
        }
        this.languageCode = this.activatedRoute.snapshot.queryParams['languageCode'];
        this.url = this.activatedRoute.snapshot.queryParams['backendUrl'] + this.urlExtension;
        this.activityId = this.activatedRoute.snapshot.queryParams['activityId'];
    }

    onPaymentStatus(event) {
        if (event.ok) {
            localStorage.setItem('error', String(event.body.success === false));
        } else {
            localStorage.setItem('error', 'true');
        }
        localStorage.setItem('paymentComplete', 'true');
    }

    confirmationPayment(nonce: string, chargeAmount: number): Observable<any> {
        return new Observable(
            observer => {
                this.confirmationPaymentDeep(nonce, chargeAmount)
                    .subscribe(
                        (result: HttpResponse<any>) => {
                            observer.next(result);
                            observer.complete();
                        },
                        (error: HttpErrorResponse) => {
                            observer.next(error);
                            observer.complete();
                        }
                    );
            }
        );
    }

    confirmationPaymentDeep(nonce: string, chargeAmount: number): Observable<any> {
        const data = {
            nonce, chargeAmount, activityId: this.activityId
        };
        return this.http.post(this.url, data, {observe: 'response'});
    }

    getCurrencySymbol(): string {
        if (this.currencyCode === 'EUR') {
            return ' €';
        } else if (this.currencyCode === 'USD') {
            return ' $';
        }
        return '';
    }

}
